<template>
  <div class="summary-page">
    <en-table-layout ref="tableLayout" :pagination="false" :tableData="tableData.data">
      <template slot="header">
        <el-tabs v-if="editableTabs.length > 1" v-model="params.account_type" type="card" @tab-click="getDataList">
          <el-tab-pane :key="item.name" v-for="item in editableTabs" :label="item.title" :name="item.name">
            {{ item.content }}
          </el-tab-pane>
        </el-tabs>
      </template>

      <template slot="toolbar">
        <el-form-item style="margin-top:10px" label="月份选择" class="col-auto">
          <el-date-picker v-model="params.sn" value-format="yyyyMM" type="month" placeholder="选择月份"
            @change="dateChange"></el-date-picker>
        </el-form-item>
      </template>

      <template slot="table-columns" v-if="params.account_type == '1'">
        <el-table-column width="120" label="日期">
          <template slot-scope="scope">{{ scope.row.sn | sn_filter }}</template>
        </el-table-column>
        <el-table-column width="140" label="账单总额">
          <template slot="header">
            <el-tooltip style="max-width: 200px" effect="dark" placement="top">
              <label class="cursor-pointer">账单总额<i class="el-icon-warning-outline"></i></label>
              <div slot="content">
                统计当月用户购买非商城自有商品的金额数据
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.statement_total }}</template>
        </el-table-column>
        <el-table-column width="140" label="退款总额" prop="refund_total" />
        <el-table-column show-overflow-tooltip label="备注信息" prop="remark" />
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="detail(scope.row)">
              查看详情
            </el-button>
            <el-button size="mini" type="primary" @click="editRemark(scope.row)">
              备注
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template slot="table-columns" v-if="params.account_type == '2'">
        <el-table-column width="120" label="日期">
          <template slot-scope="scope">{{ scope.row.sn | sn_filter }}</template>
        </el-table-column>
        <el-table-column width="140" label="账单总额">
          <template slot="header">
            <el-tooltip style="max-width: 200px" effect="dark" placement="top">
              <label class="cursor-pointer">账单总额<i class="el-icon-warning-outline"></i></label>
              <div slot="content">
                统计当月用户购买商城自提商品的金额数据
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.statement_total }}</template>
        </el-table-column>
        <el-table-column width="180" label="餐类商品订单支出总额" prop="meal_total" />
        <el-table-column width="180" label="其他商品订单支出总额" prop="pickup_total" />
        <el-table-column label="备注信息" prop="remark" />
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="detail(scope.row)">
              查看详情
            </el-button>
            <el-button size="mini" type="primary" @click="editRemark(scope.row)">
              备注
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template slot="table-columns" v-if="params.account_type == '3'">
        <el-table-column width="120" label="日期">
          <template slot-scope="scope">{{ scope.row.sn | sn_filter }}</template>
        </el-table-column>
        <el-table-column width="120" label="线下充值总额" prop="offline_topup_total" />
        <el-table-column width="120" label="线下退款总额" prop="offline_refund_total" />
        <el-table-column width="120" label="线下消费总额" prop="offline_consumption_total" />
        <el-table-column label="备注信息" prop="remark" />
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="detail(scope.row)">
              查看详情
            </el-button>
            <el-button size="mini" type="primary" @click="editRemark(scope.row)">
              备注
            </el-button>
          </template>
        </el-table-column>
      </template>
      <template slot="table-columns" v-if="params.account_type == '4'">
        <el-table-column width="120" label="日期">
          <template slot-scope="scope">{{ scope.row.sn | sn_filter }}</template>
        </el-table-column>
        <el-table-column width="140" label="账单总额" key="statement_total3">
          <template slot="header">
            <el-tooltip style="max-width: 200px" effect="dark" placement="top">
              <label class="cursor-pointer">账单总额<i class="el-icon-warning-outline"></i></label>
              <div slot="content">
                统计当月用户购买商城邮寄商品的金额数据
              </div>
            </el-tooltip>
          </template>
          <template slot-scope="scope">{{ scope.row.statement_total }}</template>
        </el-table-column>
        <el-table-column width="140" key="refund_total3" label="退款总额" prop="refund_total" />
        <el-table-column show-overflow-tooltip label="备注信息" prop="remark" />
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="detail(scope.row)">
              查看详情
            </el-button>
            <el-button size="mini" type="primary" @click="editRemark(scope.row)">
              备注
            </el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>
    <!-- 礼包备注 -->
    <x-dialog :proxy="remarkDialog">
      <el-form :model="remarkForm" ref="remarkForm" label-width="80px">
        <br />
        <el-form-item label="备注信息">
          <el-input v-model="remarkForm.remake" type="textarea" show-word-limit :rows="4" resize="none"
            placeholder="请输入100字以内的备注信息" :maxlength="100" clearable></el-input>
        </el-form-item>
        <br />
      </el-form>
    </x-dialog>
  </div>
</template>

<script>
import { pickupAccountStatementTotalList, updatePickupAccountStatementTotalRemake } from '@/api/account';
import * as API_order from "@/api/order";
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import { getCnpcShopIds, getSellerShopExtAuth } from "@/api/account"
export default {
  name: "member_bill_list",
  components: { XDialog, EnTableLayout },
  data () {
    return {
      has_self_goods: false,// 是否显示大平台商城自有商品开关
      shop_self_goods_open: false,// 商城自提商品开关
      shop_self_mailed_goods_open: false,// 商城邮寄商品开关
      ispetroChina: false, // 是否是中石油商城
      remarkForm: {},
      remarkDialog: $xDialog.create({
        width: "500px",
        beforeConfirm: () => this.submitRemark(),
      }),
      editableTabs: [],
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        account_type: '1',
        sn: ''
      },
      /** 列表数据 */
      tableData: {},
    };
  },
  filters: {
    sn_filter (sn) {
      let snArr = sn.split('');
      snArr.splice(4, 0, '-');
      return snArr.join('');

    }
  },
  async created () {
    const resStatus = await API_order.getShopExtAuth();
    this.has_self_goods = resStatus.shop_self_goods_open === 'OPEN';
    const respost = await getSellerShopExtAuth(this.$store.getters.shopInfo.shop_id)
    this.shop_self_goods_open = respost.shop_self_goods_open === 'OPEN'// 商城自提商品开关
    this.shop_self_mailed_goods_open = respost.shop_self_mailed_goods_open === 'OPEN'// 商城邮寄商品开关
    //判断是否是中石油商城
    let shopidList = await getCnpcShopIds()
    shopidList = shopidList.data.split(',')
    this.ispetroChina = shopidList.includes((this.$store.getters.shopInfo.shop_id).toString())
    this.editableTabs = []
    const editableTabs = [
      {
        title: '好物到家',
        name: '1'
      },
      {
        title: '自提商品',
        name: '2'
      },
      {
        title: '线下消费',
        name: '3'
      },
      {
        title: '商城邮寄商品',
        name: '4'
      }
    ]

    if (!this.ispetroChina) {//非中石油的双选商城 判断有没有权限页签 判断有没有商城自有商品权限或者商城自提商品权限或者商城邮寄商品权限
      this.editableTabs = editableTabs.filter(item => ['2', '4'].includes(item.name))
      this.params.account_type = '2';
      if (!this.shop_self_goods_open) {
        this.editableTabs = editableTabs.filter(item => ['4'].includes(item.name))
        this.params.account_type = '4';
      }
      if (!this.shop_self_mailed_goods_open) {
        this.editableTabs = editableTabs.filter(item => ['2'].includes(item.name))
        this.params.account_type = '2';
      }
    } else {
      this.editableTabs = editableTabs
    }
    this.$refs.tableLayout.$refs.table.doLayout();
    this.getDataList();
  },
  activated () {
    this.getDataList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  methods: {
    detail (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      this.$router.push({
        path: '/finance/billListDetail',
        query: {
          sn: row.sn,
          type: row.account_type,
          ispetroChina: this.ispetroChina
        }
      })
    },
    editRemark (row) {
      this.remarkForm = {
        id: row.id,
        remake: "",
      };
      this.remarkDialog.display({
        title: "备注",
      });
    },
    submitRemark () {
      console.log(this.remarkForm);
      updatePickupAccountStatementTotalRemake(this.remarkForm)
        .then((res) => {
          this.remarkDialog.visible = false;
          this.getDataList();
          this.$message.success("提交成功");
        })
        .catch(() => { });
    },
    dateChange () {
      this.getDataList();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.getDataList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getDataList();
    },

    /** 分页大小发生改变 */
    handlePageSizeChange1 (size) {
      this.params.page_size = size;
      this.getDataList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange1 (page) {
      this.params.page_no = page;
      this.getDataList();
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params.page_no = 1;
      this.getDataList()
    },
    getDataList () {
      this.loading = true;
      let params = JSON.parse(JSON.stringify(this.params));
      if (params.sn == '') delete params.sn;
      pickupAccountStatementTotalList(params).then((response) => {
        this.loading = false;
        this.tableData = response;
        this.$refs.tableLayout.$refs.table.doLayout();
      });
    },
  },
};
</script>

<style lang="scss">
.summary-page {
  background: #fff;

  .en-table-header {
    padding: 12px;
  }

  .en-table-layout-toolbar {
    padding: 0;
  }
}
</style>
